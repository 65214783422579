<template>
    <!-- pagination -->
    <div class="w-90 center pb5">
        <div class="flex justify-between items-center pa3" style="background: #f6f8fe">
            <div class="flex items-center w-50">
                <div class="pr3-l pr2">
                    Showing
                </div>
                <!-- w-20 -->
                <select
                    class="input-style w-30x"
                    style="border: 1px solid rgb(151, 145, 145); background: #ffffff;"
                    v-model="route.query.limit"
                    @change="
                        onNavigate({
                            page: route.query.page,
                            limit: route.query.limit || 25,
                            filterType: route.query.filterType || 'adminRange',
                            filterByCategory: route.query?.category || '',
                            filterByBrand: route.query?.brand || '',
                            filterByQuantity: route.query?.quantity || '',
                            filterByCost: route.query?.cost || '',
                            filterByTags: route.query?.tags || '',
                            startDate: route.query.startDate,
                            endDate: route.query.endDate,
                            howLongInNumber: route.query.howLongInNumber,
                            oneOfDaysWeeksMonthsYears: route.query.oneOfDaysWeeksMonthsYears,
                            sort: route.query.sort
                        })
                    "
                >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="80">80</option>
                    <option value="100">100</option>
                </select>
                <div class="pl3 db-l dn">items per page</div>
            </div>
            <div class="flex">
                <div
                    class="pl2 pl3-l active-page-number"
                    v-if="pages?.prev?.page"
                    @click="
                        onNavigate({
                            page: route.query.page,
                            limit: route.query.limit || 25,
                            filterType: route.query.filterType || 'adminRange',
                            filterByCategory: route.query?.category || '',
                            filterByBrand: route.query?.brand || '',
                            filterByQuantity: route.query?.quantity || '',
                            filterByCost: route.query?.cost || '',
                            filterByTags: route.query?.tags || '',
                            startDate: route.query.startDate,
                            endDate: route.query.endDate,
                            howLongInNumber: route.query.howLongInNumber,
                            oneOfDaysWeeksMonthsYears: route.query.oneOfDaysWeeksMonthsYears,
                            sort: route.query.sort
                        })
                    "
                >
                    <router-link
                        :to="{
                            name: routeName,
                            query: {
                                page: pages?.prev?.page,
                                limit: pages?.prev?.limit,
                                filterType: pages?.prev?.filterType || 'adminRange',
                                filterByCategory: route.query?.category || '',
                                filterByBrand: route.query?.brand || '',
                                filterByQuantity: route.query?.quantity || '',
                                filterByCost: route.query?.cost || '',
                                filterByTags: route.query?.tags || '',
                                startDate: pages?.prev?.startDate,
                                endDate: pages?.prev?.endDate,
                                howLongInNumber: pages?.prev?.howLongInNumber,
                                oneOfDaysWeeksMonthsYears: pages?.prev?.oneOfDaysWeeksMonthsYears,
                                sort: pages?.prev?.sort
                            }
                        }"
                        >Prev</router-link
                    >
                </div>

                <div class="active-page-number pl2 pl3-l">
                    {{ (pages?.next?.page && pages?.next?.page - 1) || (pages?.prev?.page && pages?.prev?.page + 1) || 1 }}
                </div>
                <!--        <div class="pl2 pl3-l">2</div>-->

                <!--        <div-->
                <!--          class="pl2 pl3-l active-page-number"-->
                <!--          v-if="pages?.next?.page"-->
                <!--          @click="onNavigate({ page: route.query.page, limit: route.query.limit || 25 })"-->
                <!--        >-->
                <!-- <router-link :to="{ name: routeName, query: { page: pages?.next?.page, limit: pages?.next?.limit } }">Next</router-link>-->
                <!--        </div>-->

                <div
                    class="pl2 pl3-l active-page-number"
                    v-if="pages?.next?.page"
                    @click="
                        onNavigate({
                            page: route.query.page,
                            limit: route.query.limit || 25,
                            filterType: route.query.filterType || 'adminRange',
                            filterByCategory: route.query?.category || '',
                            filterByBrand: route.query?.brand || '',
                            filterByQuantity: route.query?.quantity || '',
                            filterByCost: route.query?.cost || '',
                            filterByTags: route.query?.tags || '',
                            startDate: route.query.startDate,
                            endDate: route.query.endDate,
                            howLongInNumber: route.query.howLongInNumber,
                            oneOfDaysWeeksMonthsYears: route.query.oneOfDaysWeeksMonthsYears,
                            sort: route.query.sort
                        })
                    "
                >
                    <router-link
                        :to="{
                            name: routeName,
                            query: {
                                page: pages?.next?.page,
                                limit: pages?.next?.limit,
                                filterType: pages?.next?.filterType || 'adminRange',
                                filterByCategory: route.query?.category || '',
                                filterByBrand: route.query?.brand || '',
                                filterByQuantity: route.query?.quantity || '',
                                filterByCost: route.query?.cost || '',
                                filterByTags: route.query?.tags || '',
                                startDate: pages?.next?.startDate,
                                endDate: pages?.next?.endDate,
                                howLongInNumber: pages?.next?.howLongInNumber,
                                oneOfDaysWeeksMonthsYears: pages?.next?.oneOfDaysWeeksMonthsYears,
                                sort: pages?.next?.sort
                            }
                        }"
                        >Next</router-link
                    >
                </div>
            </div>
        </div>
    </div>
    <!-- pagination -->
</template>

<script>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

export default {
    name: 'Pagination',
    props: {
        pages: Object,
        routeName: String,
        onNavigate: Function
    },

    setup() {
        const route = useRoute()

        onMounted(() => (route.query.limit = 25))

        return { route }
    }
}
</script>

<style scoped></style>
